<template>
    <div class="img-ps">photo pb</div>
    <h1>Pesage Dynamique</h1>
        <br>
        <br>
        <div>
            <div class="img-pb">Bande transporteuse</div>
            <div class="text-pb">Le pesage dynamique sur bande transporteuse sert à connaitre le débit de matière sur celle ci et d'en déduirele poid transporté pendant un temps donné.</div>
        </div>
        <br>

        <div class="text-exemple">EXEMPLE CONCRETS : <br> <br>Déterminer la production journalière d'une mine, ou d'une exploitation de carrière... <br> <br>
        Réaliser des chargements de camions, de trains ou de bateaux en continu, <br> <br> Contrôle du débit d'une bande transporteuse avec asservissement d'un extracteur, d'un crible...</div>
        <div class="img-exemple1">photo exemple1</div>
        <div class="img-exemple2">photo exemple2</div>
        <br>
        <br>
        <br>
        <br>
        <br>
        <br>
        <br>
        <br>
        <h1 style="text-decoration: underline;">Demande de devis ou contact :</h1>
        <br>
        <br>
        <br>
        <br>
        <br>
        <br>
        <br>
        <br>
        <br>
        <br>
        <div class="form-devis">
            <form action="https://formsubmit.co/arthur1schuster@gmail.com" method="POST">
                <input type="hidden" name="_subject" value="Devis Pesage Dynamique !">
                <b>Nom :</b>
                <input type="text" name="Nom" required placeholder="Nom*">
                <b>Adresse mail :</b>
                <input type="email" name="email" required placeholder="Email Address*">
                <b>Numéro de téléphone :</b>
                <input type="text" name="Numéro de téléphone" required placeholder="Numéro de téléphone*">
                <b>Entreprise :</b>
                <input type="text" name="Entreprise" required placeholder="Entreprise*">
                <b>Votre demande de contact ou de devis :</b>
                <textarea type="text" name="Demande" required placeholder="Votre demande de devis ou d'informations.*"></textarea>
                <button type="submit">Envoyer</button>
                <input type="hidden" name="_replyto" value="Merci pour votre demande de Devis ou d'information et bienvenu chez CNP.">
                <input type="hidden" name="_autoresponse" value="Merci pour votre demande de Devis ou d'information et bienvenu chez CNP.">
                <input type="hidden" name="_template" value="box">
            </form>
        </div>
</template>




<style>
/* .img-ps {
    position: relative;
    width: 1370px;
    height: 650px;
    top: -10px;
    left: -40px;
    margin: 0px auto;
    overflow: hidden;
    border: 1px solid #000000;
} */

b {
    color: black;
    text-align: left;
}

.text-exemple {
    position: relative;
    width: 740px;
    height: 260px;
    border: 2px solid black;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    border-color: black;
    text-align: center;
    color: black;
    left: 225px;
    bottom: -40px;
    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
    font-size: 16px;
    transition: 0.5s ease-in-out;
    background-color: #123e6380;
    overflow-y: auto;
    transition: 0.5s ease-in-out;
    display: flex;               /* Active Flexbox */
    justify-content: center;      /* Centre horizontalement */
    align-items: center;
}

.text-exemple:hover {
    border-radius: 25px;
    color: white;
    background: #123E63;
    transform: scale(1.1) translateY(-30px);
}

.img-exemple1 {
    position: relative;
    width: 369px;
    height: 260px;
    border: 2px solid black;
    border-bottom-right-radius: 15px;
    border-color: black;
    text-align: center;
    color: black;
    left: 596px;
    bottom: -38px;
    transition: 0.5s ease-in-out;
    overflow-y: auto;
}

.img-exemple1:hover {
    transform: scale(1.1) translateX(40px) translateY(30px);
    border-radius: 25px;
}

.img-exemple2 {
    position: relative;
    width: 369px;
    height: 260px;
    border: 2px solid black;
    border-bottom-left-radius: 15px;
    border-color: black;
    text-align: center;
    color: black;
    left: 225px;
    bottom: 226px;
    transition: 0.5s ease-in-out;
    overflow-y: auto;
}

.img-exemple2:hover {
    transform: scale(1.1) translateX(-40px) translateY(30px);
    border-radius: 25px;
}

</style>