
<template>
    <div>
      <br>
      <br>
      <br>
      <br>
      <br>
      <br>
      <h1 class="titre-metro">Metrologie Légale</h1>
      <br>
      <br>
      <br>
      <br>
      <br>
      <br>
      <br>
      <div class="box-metro">
      <div class="guide-metro">
        <div class="texte-premier">
            Métrologie Légale :
        </div>
      <br>
      <br>
      <br>

<br>
<br>
L'application de cette réglementation peut parfois sembler complexe et entraîner des impacts économiques significatifs dans vos activités quotidiennes.


<br>
<br>
Chez CNP, nous sommes spécialisés dans la fourniture et la maintenance d'instruments de pesage, en parfaite conformité avec la métrologie légale. La métrologie légale est un domaine crucial pour garantir des transactions commerciales transparentes, équitables et sécurisées. Elle régit l’utilisation des instruments de mesure, comme les balances et autres dispositifs de pesée, afin de s'assurer que les poids sont mesurés de manière fiable et conforme à la réglementation en vigueur.
<br>
<br>

Voici quelques points clés sur la métrologie légale dans le pesage :
<br>
<br>

Homologation des instruments de pesée
Tous les instruments de pesage utilisés dans le cadre de transactions commerciales doivent être homologués par les autorités compétentes. Cela signifie qu'ils sont vérifiés pour leur précision et leur conformité avec les normes légales. Avant leur mise en service, nos équipements sont systématiquement soumis à des tests rigoureux pour garantir leur conformité.
<br>
<br>

Vérification et étalonnage réguliers
La précision des instruments de pesée est essentielle. C’est pourquoi nous proposons des services d'étalonnage et de vérification périodique. Ces contrôles permettent de s'assurer que les balances et autres instruments sont toujours fiables et conformes aux normes en vigueur, en respectant les tolérances définies par la législation.
<br>
<br>

Respect des tolérances légales
Chaque instrument de pesée possède des tolérances de mesure spécifiques qu’il ne doit pas dépasser pour être conforme aux exigences légales. Nous veillons à ce que tous nos équipements respectent scrupuleusement ces tolérances afin d’éviter toute contestation ou litige lors des transactions commerciales.
<br>
<br>

Conformité et audit
Dans le cadre de la métrologie légale, nous accompagnons nos clients dans le respect des audits de conformité. En tant que professionnels du secteur, nous garantissons que votre équipement de pesage reste conforme à toutes les exigences légales, même en cas de changement de réglementation.
<br>
<br>

En tant qu’entreprise spécialisée, nous nous engageons à vous fournir des instruments de pesage qui répondent aux plus hauts standards de qualité et de précision, tout en assurant leur conformité avec les exigences de la métrologie légale. Cela vous permet de travailler en toute sérénité, en sachant que vos opérations de pesage sont fiables et conformes aux obligations légales.
<br>
<br>
Nous agissons sur Ces 2 types d'instruments de pesage :
<br>
<br>

<li>
    IPFA (Instrument de Pesage à Fonctionnement Automatique) (Totalisateurs discontinus, doseurs, trieurs étiqueteurs)</li>
<br>
<li>
    IPFNA (Instrument de Pesage à Fonctionnement Non Automatique) (Balances, bascules, ponts bascules, trémies peseuses, etc..)</li>
<br>
<br>

</div>
    </div>
</div>
    <br>
    <br>
    <br>
    <br>
    <br>
    <br>
    <br>
    <br>
    <br>
    <br>
    <br>

</template>

<style>
    .guide-metro {
        position: relative;
        margin: 10px;
        text-align: left;
    }

    .texte-premier {
        font-size: 28px;
        text-align: left;
        text-decoration: underline;
    }

    .titre-metro {
        color: red;
        font-size: 38px;
    }

    .box-metro {
        position: relative;
        width: 78%;
        left: 150px;
        height: 110%;
        background-color: rgba(18, 62, 99, 0.2);
        border: 3px solid black;
        border-radius: 10px;
        transition: 0.5s ease-in-out;

    }

</style>