<template>
    <div>
        <img class="image-quisommesnous" src="../../assets/facade.jpg" alt="secteurs">
        <br>
        <div class="bandeau_titre">
            <div class="partenaire_titre">Qui sommes nous ?</div>
            <div class="barre_blanche"></div>
        </div>
        <br>
        <br>
        <br>
        <div class="titre-qsn"> 
            Qui sommes nous ?
        </div> 
        <br>
        <br>
        <br>
        <div class="histoire-cnp"> <br><br> Fondée en 1983 par une équipe de techniciens spécialisés dans le pesage, CNP Sarl s'est rapidement imposée comme un acteur clé dans le domaine des ponts bascules routiers et ferroviaires.
            <br><br>
            <br><br>
            À l'époque, l'industrialisation de la Lorraine a favorisé le développement rapide de l'entreprise. Nos locaux, comprenant ateliers et bureaux, sont situés dans la zone industrielle de Prouvé, au nord de Nancy, offrant ainsi un accès facile aux camions de livraison.
            <br><br>
            <br><br>
            CNP Sarl se distingue par son expertise en métrologie légale, spécialisée dans les équipements de pesage destinés au commerce, à l'industrie et aux PME. Nous avons également développé une expertise dans la mesure de la teneur en eau des céréales, avec des humidimètres conformes à une réglementation proche de celle des instruments de pesage.
        <br><br>
        <br><br>
        </div>
        <br>
        <br>
        <br>
        <br>
        <br>
        <br>
        <br>
        <br>
        <br>
        <br>
        <br>
        <br>
        <br>
        <br>
        <br>
        <br>
        <br>
        <br>
        <br>
        <br>
        <br>
        <br>
        <br>
        <br>
        <br>
        <br>
        <br>
        <br>
        <br>
        <br>
        <br>
        <br>
        <br>
        <br>
        <br>
        <br>
        <br>
        <br>
        <br>
        <br>
        <br>
    </div>
</template>



<style>

@import url('https://fonts.cdnfonts.com/css/century-schoolbook');

.titre-qsn {
    font-size: 40px;
    font-weight: bold;
}

.histoire-cnp {
    position: absolute;
    width: 70%;
    height: 100%;
    left: 15%;
    /* border: 2px solid rgba(0, 0, 0, 0.5) */
    border-radius: 10px;
    color: black;
    text-align: center;
    font-size: 23px;
    font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
    overflow-y: none;
    transition: 0.5s ease-in-out;
}

/* .histoire-cnp:hover {
    transform: scale(1.2);
    background-color: rgba(18, 62, 99, 0.7);
} */

.titre-quisommesnous {
    position: relative;
    z-index: 2;
    top: -550px;
    left: 100px;
    background-color: rgba(18, 62, 99, 0.3);
    width: 35%;
    height: 10%;
    color: white;
    font-size: 50px;
    transition: 0.5s ease-in-out;
}

.titre-quisommesnous:hover {
    transform: scale(1.2);
    background-color: rgba(18, 62, 99, 0.9);
}

.image-quisommesnous {
    position: relative;
    width: 106%;
    height: 640px;
    left: -32px;
}

h1 {
    text-align: center;
}

div {
    text-align: center;
}

.navbar {
    color: white;
}
</style>